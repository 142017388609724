import ReactGA from 'react-ga';

//export const API = process.env.REACT_APP_API;
export const API = '/api';

//GOOGLE
export const initGA = () => {
	console.log('SYSTEM MSG --> GA Init...');
	ReactGA.initialize('UA-176503569-1');
};

export const logPageView = () => {
	ReactGA.set({ page: window.location.pathname });
	//ReactGA.pageview(window.location.pathname + window.location.search);
	ReactGA.pageview(window.location.pathname);
};
