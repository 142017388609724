import React from 'react';
import ReactDOM from 'react-dom';

import Routes from './Router';

import './index.css';

//MATERIAL UI - Theme
import theme from './themeConfig';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<StylesProvider injectFirst>
				<Routes />
			</StylesProvider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
