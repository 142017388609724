import { lazy } from 'react';

const Admin = lazy(() => import('../core/pages/Admin'));
const AdminProfile = lazy(() => import('../core/pages/Admin/Profile'));
const Templates = lazy(() => import('../core/pages/General/Templates'));
const PatientEdit = lazy(() => import('../core/pages/Patient/Edit'));
const Venues = lazy(() => import('../core/pages/Venues'));
const ConsultAttend = lazy(() => import('../core/pages/Consultation/Attend'));
const PendingPaymentPage = lazy(() => import('../core/pages/General/PendingPayment'));
const WaitingPage = lazy(() => import('../core/pages/General/Waiting'));
const StatsPage = lazy(() => import('../core/pages/Stats'));
const StatsTagsPage = lazy(() => import('../core/pages/Stats/Tags'));
const ConsultationsAllTablePage = lazy(() =>
	import('../core/pages/Consultation/AllTable')
);
const ToDo = lazy(() => import('../core/pages/ToDo'));
const AssistentCreatePage = lazy(() => import('../core/pages/Assistent/Create'));
const Subscription = lazy(() => import('../core/pages/paypal/Subscription'));

const adminRoutes = [
	{ path: '/admin', component: Admin },
	{ path: '/admin/profile', component: AdminProfile },
	{ path: '/admin/venues', component: Venues },
	{ path: '/admin/templates', component: Templates },
	{ path: '/admin/patient/edit/:patientId', component: PatientEdit },
	{ path: '/admin/consultation/attend/:consultationId', component: ConsultAttend },
	{ path: '/admin/payment/pending', component: PendingPaymentPage },
	{ path: '/admin/waiting', component: WaitingPage },
	{ path: '/admin/stats', component: StatsPage },
	{ path: '/admin/stats-tags', component: StatsTagsPage },
	{ path: '/admin/table-all', component: ConsultationsAllTablePage },
	{ path: '/admin/to-do', component: ToDo },
	{ path: '/admin/assistent/create', component: AssistentCreatePage },
	{ path: '/subscription', component: Subscription },
];

export default adminRoutes;
