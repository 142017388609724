import { lazy } from 'react';

const Private = lazy(() => import('../core/pages/Assistent'));

const PatientCreate = lazy(() => import('../core/pages/Patient/Create'));
const PatientList = lazy(() => import('../core/pages/Patient/List'));
const PatientProfile = lazy(() => import('../core/pages/Patient/Profile'));
const ConsultationCreate = lazy(() => import('../core/pages/Consultation/Create'));
const ConsultationsWaitingPage = lazy(() => import('../core/pages/Consultation/Wating'));
const ConsultationPaymentPendingPage = lazy(() =>
	import('../core/pages/Consultation/PaymentPending')
);
const ProcedimentWaitingPage = lazy(() => import('../core/pages/Procediment/Waiting'));
const ProcedimentPaymentPendingPage = lazy(() =>
	import('../core/pages/Procediment/PaymentPending')
);
const ToDoPage = lazy(() => import('../core/pages/ToDo'));

const privateRoutes = [
	{ path: '/private', component: Private },
	{ path: '/patient/create', component: PatientCreate },
	{ path: '/patient/list', component: PatientList },
	{ path: '/patient/profile/:patientId', component: PatientProfile },
	{ path: '/consultation/create/:patientId', component: ConsultationCreate },
	{ path: '/consultation/waiting-page', component: ConsultationsWaitingPage },
	{ path: '/consultation/payment-pending', component: ConsultationPaymentPendingPage },
	{ path: '/procediment/waiting', component: ProcedimentWaitingPage },
	{ path: '/procediment/payment-pending', component: ProcedimentPaymentPendingPage },
	{ path: '/private/to-do', component: ToDoPage },
];

export default privateRoutes;
