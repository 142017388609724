//MATERIAL UI - Theme

// import { createTheme } from '@material-ui/core/styles';
import { createTheme } from '@mui/material/styles';
//IMPORT COLORS

const theme = createTheme({
	palette: {
		primary: {
			/* main: '#093170', */
			main: '#728FF8',
		},
		secondary: {
			/* main: '#47A10D', */
			main: '#4444FF',
		},
		background: {
			default: '#F3F8FE',
		},
	},
	typography: {
		button: {
			fontWeight: 400,
		},
	},
});

export default theme;
