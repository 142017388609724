import { lazy } from 'react';

const App = lazy(() => import('../App'));

const Test = lazy(() => import('../core/pages/test'));
const Terms = lazy(() => import('../core/pages/GeneralPublic/Terms'));
const Signup = lazy(() => import('../auth/Signup'));
const Signin = lazy(() => import('../auth/Signin'));
const Forgot = lazy(() => import('../auth/Forgot'));
const Reset = lazy(() => import('../auth/Reset'));
const Activate = lazy(() => import('../auth/Activate'));

const publicRoutes = [
	{ path: '/test', component: Test },
	{ path: '/', component: App },
	{ path: '/terms', component: Terms },
	// { path: '/subscription', component: Subscription },
	{ path: '/signup', component: Signup },
	{ path: '/signin', component: Signin },
	{ path: '/auth/password/forgot', component: Forgot },
	{ path: '/auth/password/reset/:token', component: Reset },
	{ path: '/auth/activate/:token', component: Activate },
	{ path: '/auth/activate/:token', component: Activate },
];

export default publicRoutes;
