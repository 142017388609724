import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';

import { initGA, logPageView } from './config';

//Import private route component
import PrivateRoute from './auth/PrivateRoute';
import AdminRoute from './auth/AdminRoute';
import SuperAdminRoute from './auth/SuperAdminRoute';

//Import Routes
import publicRoutes from './routes/publicRoutes.js';
import privateRoutes from './routes/privateRoutes.js';
import adminRoutes from './routes/adminRoutes.js';
import superAdminRoutes from './routes/superAdminRoutes.js';

const Routes = () => {
	useEffect(() => {
		initGA();
		logPageView();
	}, []);

	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div style={{ textAlign: 'center' }}>
						<div>
							<Skeleton variant='rect' height={200} />
						</div>
						<br />
						<Skeleton variant='rect' height={400} />
						<br />
						<Skeleton variant='rect' height={300} />
						<br />
						<Skeleton variant='rect' height={600} />
						<br />
						<Skeleton variant='rect' height={100} />
					</div>
				}
			>
				<Switch>
					{publicRoutes.map((publicRoute, idx) => (
						<Route
							path={publicRoute.path}
							component={publicRoute.component}
							key={idx}
							exact
						/>
					))}
					{privateRoutes.map((privateRoute, idx) => (
						<PrivateRoute
							path={privateRoute.path}
							component={privateRoute.component}
							key={idx}
							exact
						/>
					))}
					{adminRoutes.map((adminRoute, idx) => (
						<AdminRoute
							path={adminRoute.path}
							component={adminRoute.component}
							key={idx}
							exact
						/>
					))}
					{superAdminRoutes.map((superAdminRoute, idx) => (
						<SuperAdminRoute
							path={superAdminRoute.path}
							component={superAdminRoute.component}
							key={idx}
							exact
						/>
					))}
				</Switch>
			</Suspense>
		</BrowserRouter>
	);
};

export default Routes;
