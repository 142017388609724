import cookie from 'js-cookie';

//  METHOD - Set in cookie
export const setCookie = (key, value) => {
	if (window !== 'undefined') {
		cookie.set(key, value, {
			expires: 365,
		});
	}
};

//  METHOD - Remove from cookie
export const removeCookie = (key) => {
	if (window !== 'undefined') {
		cookie.remove(key);
	}
};

//  METHOD - Get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
	if (window !== 'undefined') {
		return cookie.get(key);
	}
};

//  METHOD - Set in localstorage
export const setLocalStorage = (key, value) => {
	if (window !== 'undefined') {
		localStorage.setItem(key, JSON.stringify(value));
	}
};

//  METHOD - Remove from local Storage
export const removeLocalStorage = (key) => {
	if (window !== 'undefined') {
		localStorage.removeItem(key);
	}
};

//  MIDDLEWARE - Auth user by passing data to cookie and local storage during signin
export const authenticate = (response, next) => {
	// console.log('DEBUG --> AUTH HELPER ON SIGNIN RESPONSE: ', response);
	setCookie('token', response.data.token);
	setLocalStorage('user', response.data.user);
	next();
};

//  METHOD - Access user info from localstorage
export const isAuth = () => {
	if (window !== 'undefined') {
		const cookieChecked = getCookie('token');
		if (cookieChecked) {
			if (localStorage.getItem('user')) {
				return JSON.parse(localStorage.getItem('user'));
			} else {
				return false;
			}
		}
	}
};

// SIGNOUT
export const signout = (next) => {
	removeCookie('token');
	removeLocalStorage('user');
	next();
};

//UPDATE USER
export const updateUser = (response, next) => {
	// console.log('DEBUG --> UPDATE USER IN LOCAL STORAGE', response);
	if (window !== 'undefined') {
		let auth = JSON.parse(localStorage.getItem('user'));
		auth = response.data;
		localStorage.setItem('user', JSON.stringify(auth));
	}
	next();
};
